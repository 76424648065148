import { NodeDisableMode } from '../definitions/enums';


export function getDefaultDisableMode(isNodeCountable: boolean): NodeDisableMode {
    if (isNodeCountable) {
        return NodeDisableMode.EnableIfQty0;
    }
    return NodeDisableMode.Enable;
}


export interface DisableInfo {
    disableMode: NodeDisableMode;
    enabledIfExpression?: string;
    enabledIfDefaultExpression?: string;
    enabledIfOverride?: boolean;
    enabledIfErrors: string[];
    enabledIfWarnings: string[];
    isNodeHidden: boolean;
}

export const DEFAULT_DISABLE_INFO: DisableInfo = {
    disableMode: NodeDisableMode.Enable,
    enabledIfExpression: '',
    enabledIfErrors: [],
    enabledIfWarnings: [],
    isNodeHidden: false
};
